/* .row{

}

.col-75 {
width: 75%
}

.col-25 {
  width: 25%
} */

#questions {
    color: red;
    margin: 10px 10px 30px 40px;
  }
  
  button {
    color: blue;
    padding: 16px;
    text-align: left;
    font-size: 16px;
    /* opacity: 15%;  */
    transition: 0.3s;
    display: block;
    cursor: pointer;
    margin-left: 10px;
    /* width: 100%;  */
  }
  
  button:hover {
    color: red;
  }
  
  #correct {
    margin: 10px;
  }
  
  #incorrect {
    margin: 10px;
  }
  