.sidebar {
    margin-left: 30px;
    margin-top: 15px;
}
.howpic { 
    top: 14%;
    left: 50%;
    position: fixed;
}
.bod {
    margin-left: 30px;
    margin-top: 15px;
    height: 800px;
}
@media (max-width: 800px) {
    .bod { 
        margin-top: 150px;
        width: 500px;
        height: 1000px;
    }
} 
@media (max-width: 800px) {
    .sidebar { margin-top: 155px;}
} 
@media (max-width: 800px) {
    .howpic { 
        top: 25%;
        left: 50%;
        position: fixed;
    }
} 
@media (max-width: 768px) {
    .howpic { 
        top: 10%;
        left: 50%;
        position: fixed;
    }
} 
