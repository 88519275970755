@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  outline: none !important;
  /* background-color: #e5ecf1 !important; */
}
.MuiSpeedDialAction-staticTooltipLabel {
  color: rgba(255, 255, 255, 0.84) !important;
  font-size: 0.8rem !important;
  background-color: #000 !important;
}
button {
  outline: none !important;
}
div {
  outline: none !important;
}
.expandText {
  /*transition: width 100ms ease-in-out;*/
  transition: width 0.2s ease-in-out;
  -webkit-transition: width 0.2s ease-in-out;
}
.expandText:hover {
  width: 100% !important;
}
.icons {
  cursor: 'pointer';
}
.icons:hover {
  background-color: '#ccc';
}
.react-datepicker__input-container {
  background: none !important;
}
.calendarPicker {
  background: #f1f3f4 !important;
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -webkit-border-radius: 8px !important;
  border-radius: 8px !important;
  min-height: 40px !important;
  color: #3c4043 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.toCalendarLabel {
  text-align: right !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.customTextField {
  background: #f1f3f4 !important;
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -webkit-border-radius: 8px !important;
  border-radius: 8px !important;
  min-height: 40px !important;
  color: #3c4043 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
#event_location-label,
#event_description-label {
  font-size: 0.85rem !important;
}
.jss7 {
  height: 34px !important;
  min-height: 20px !important;
  max-height: 34px !important;
}
.jss8 {
  max-height: 34px !important;
}
.jss659 {
  float: right !important;
  position: relative !important;
}
.files_to_view:hover {
  cursor: pointer !important;
  color: #03a9f4 !important;
  text-decoration-line: underline;
}
