#finalScope {
    color: black;
    text-align: left;
    text-decoration: black;
    text-decoration-line: underline;
    text-shadow: none;
  }
  
  #result {
    color: rgb(26, 11, 11);
    text-align: left;
    text-shadow: none;
  }

  /* #finalScope {
    color: red;
    text-align: left;
    text-decoration: red;
    text-decoration-line: underline;
    text-shadow: 1px 1px 2px black, 0 0 3px red;
  }
  
  #result {
    color: red;
    text-align: left;
    text-shadow: 1px 1px 2px black, 0 0 2px blue;
  }
   */
  